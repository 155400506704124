import i18n from '@/imports/startup/client/i18n';

import { formatToFloat } from '../../tools/formatters';

export const TABLE_HEADER = {
  DATE: 'date',
  DOCUMENT_TYPE: 'documentType',
  DOCUMENT_NUMBER: 'documentNumber',
  TOTAL_AMOUNT: 'totalAmount',
  STATUS: 'status',
  DETAILS: 'details',
};

export const prepareMissingDocumentsTableData = (missingDocuments) =>
  missingDocuments
    .map(({ id, context, possibleTypes, reference, parentDocument }) => {
      return {
        id,
        [TABLE_HEADER.DATE]: context.issueDate,
        [TABLE_HEADER.DOCUMENT_TYPE]: getMissingType(possibleTypes),
        [TABLE_HEADER.DOCUMENT_NUMBER]: reference,
        [TABLE_HEADER.TOTAL_AMOUNT]: formatToFloat(context.calculatedAmount),
        [TABLE_HEADER.STATUS]: '-',
        [TABLE_HEADER.DETAILS]: getParentDocumentText(parentDocument),
        parentDocumentId: parentDocument.id,
      };
    })
    .sort((a, b) => a.date - b.date);

const getMissingType = (type) => {
  return type.map((type) => i18n.t(`document.exports.schema.type.shortName.${type}`)).join(' / ');
};

const getParentDocumentText = (parentDocument) => {
  if (!parentDocument) return null;
  const documentDateText = new Date(parentDocument.issueDate).toLocaleDateString(i18n.locale, {
    month: 'long',
    year: 'numeric',
  });
  const documentTypeText = i18n.t(`document.exports.schema.type.shortName.${parentDocument.type}`);
  return `${i18n.t('commons.reference')}: ${documentTypeText} ${documentDateText}`;
};
