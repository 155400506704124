<template>
  <div>
    <h3 class="mb-4">{{ $t('modals.issues.paymentsDifference.title') }}</h3>
    <Table :columns="columns" :data="tableData" :hover="false" show-index border rounded />
  </div>
</template>

<script>
import { Table } from '@/modules/core';
import { useCurrency } from '@/modules/core/compositions/money-currency';

import { formatDate } from '../tools/formatters';

const TABLE_HEADER = {
  RECEIPT_DATE: 'receiptDate',
  RECEIPT: 'receipt',
  AMOUNT: 'amount',
  PAYMENT_METHOD: 'paymentMethod',
};

export default {
  components: { Table },
  props: {
    paymentDifferences: { type: Array, default: () => [] },
  },
  setup() {
    const { formatToCurrency } = useCurrency();

    return { formatToCurrency };
  },
  data() {
    return {
      selectedDocumentId: null,
    };
  },
  computed: {
    columns() {
      return [
        {
          header: this.translateFromPayment(`table.headers.${TABLE_HEADER.RECEIPT_DATE}`),
          key: TABLE_HEADER.RECEIPT_DATE,
        },
        {
          header: this.translateFromPayment(`table.headers.${TABLE_HEADER.RECEIPT}`),
          key: TABLE_HEADER.RECEIPT,
        },
        {
          header: this.translateFromPayment(`table.headers.${TABLE_HEADER.AMOUNT}`),
          key: TABLE_HEADER.AMOUNT,
        },
        {
          header: this.translateFromPayment(`table.headers.${TABLE_HEADER.PAYMENT_METHOD}`),
          key: TABLE_HEADER.PAYMENT_METHOD,
        },
      ];
    },
    tableData() {
      return this.paymentDifferences.map(
        ({ supplierPayment: { date, eventReferences, amount, isRefund, transactions } }) => {
          const document = eventReferences?.[0].document;
          return {
            [TABLE_HEADER.RECEIPT_DATE]: formatDate(date),
            [TABLE_HEADER.RECEIPT]: document
              ? `${this.$t(`document.exports.schema.type.shortName.${document.type}`)} ${document.documentNumber}`
              : '-',
            [TABLE_HEADER.AMOUNT]: this.formatToCurrency(amount * (isRefund ? -1 : 1)),
            [TABLE_HEADER.PAYMENT_METHOD]: this.$t(
              `payment.exports.paymentMethods.shortName.${transactions[0].paymentMethod}`
            ),
          };
        }
      );
    },
  },
  methods: {
    translateFromPayment(key) {
      return this.$t(`payment.paymentDifferences.${key}`);
    },
  },
};
</script>
