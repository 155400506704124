<template>
  <div>
    <h3 class="mb-4">
      {{ $t('modals.issues.unmatchedPayments.title') }}
    </h3>
    <Table :columns="columns" :data="tableData" :hover="false" show-index border rounded />
  </div>
</template>

<script>
import { Table } from '@/modules/core';
import { formatBilledDate } from '@/modules/payment';
import { useCurrency } from '@/modules/core/compositions/money-currency';

import { formatDate } from '../tools/formatters';

const TABLE_HEADER = {
  PAYMENT_DATE: 'paymentDate',
  PAYMENT_METHOD: 'paymentMethod',
  INVOICE_COUNT: 'invoiceCount',
  INVOICES_DATE: 'invoicesDate',
  PAID_AMOUNT: 'paidAmount',
};

export default {
  components: { Table },
  props: { unbilledPayments: { type: Array, default: () => [] } },
  setup() {
    const { formatToCurrency } = useCurrency();
    return { formatToCurrency };
  },
  computed: {
    columns() {
      return [
        {
          header: this.translateFromPayment(`table.headers.${TABLE_HEADER.PAYMENT_DATE}`),
          key: TABLE_HEADER.PAYMENT_DATE,
          minWidth: '100px',
        },
        {
          header: this.translateFromPayment(`table.headers.${TABLE_HEADER.PAYMENT_METHOD}`),
          key: TABLE_HEADER.PAYMENT_METHOD,
          minWidth: '120px',
        },
        {
          header: this.translateFromPayment(`table.headers.${TABLE_HEADER.INVOICE_COUNT}`),
          key: TABLE_HEADER.INVOICE_COUNT,
          minWidth: '90px',
        },
        {
          header: this.translateFromPayment(`table.headers.${TABLE_HEADER.INVOICES_DATE}`),
          key: TABLE_HEADER.INVOICES_DATE,
          minWidth: '110px',
        },
        {
          header: this.translateFromPayment(`table.headers.${TABLE_HEADER.PAID_AMOUNT}`),
          key: TABLE_HEADER.PAID_AMOUNT,
          minWidth: '120px',
        },
      ];
    },
    tableData() {
      return this.unbilledPayments.map(({ paymentId, date, paidAmount, transactions, billingLinks }) => {
        return {
          paymentId,
          [TABLE_HEADER.PAYMENT_DATE]: formatDate(date),
          [TABLE_HEADER.PAYMENT_METHOD]: transactions.length
            ? `${this.$t(`payment.exports.paymentMethods.shortName.${transactions[0].paymentMethod}`)}`
            : this.translate(`noPaymentMethod`),
          [TABLE_HEADER.INVOICE_COUNT]: billingLinks.length,
          [TABLE_HEADER.INVOICES_DATE]: formatBilledDate(billingLinks.map(({ billing }) => billing)),
          [TABLE_HEADER.PAID_AMOUNT]: this.formatToCurrency(paidAmount),
        };
      });
    },
  },
  methods: {
    formatDate,
    translateFromPayment(key) {
      return this.$t(`payment.pastPayments.${key}`);
    },
  },
};
</script>
