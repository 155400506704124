<template>
  <div>
    <h3 class="mb-4">{{ $t('modals.issues.unbilled.title') }}</h3>
    <Table
      :columns="columns"
      :data="tableData"
      :row-selection.sync="rowSelection"
      row-click-selection-toggle
      :expandable.sync="expandableRows"
      show-index
      rounded
      border
    >
      <template #cell-date="{ rowData: { date } }">
        <p>{{ formatDate(date) }}</p>
      </template>

      <template #cell-documentNumber="{ rowData: { documentNumber, documentId } }">
        <a href="" @click.prevent.stop="$emit('preview-document', documentId)">{{ documentNumber }}</a>
      </template>

      <template #cell-netAmount="{ rowData: { netAmount } }">
        <p>{{ formatMoney(netAmount) }}</p>
      </template>

      <template #cell-status="{ rowData: { status } }">
        <Status :status="status" />
      </template>

      <template #cell-details="{ rowData: { details } }">
        <div v-if="details">
          <p>{{ details.text }}</p>
          <p>{{ details.info }}</p>
        </div>
      </template>
      <template #expandable-content="{ rowData: { orderItems, id }, rowIndex }">
        <ItemsTable
          :key="id"
          :items="orderItems"
          :selected-event="rowSelection.includes(rowIndex)"
          @selection-changed="handleRowInnerSelection(rowIndex, $event)"
        />
      </template>
    </Table>
  </div>
</template>

<script>
import { clone, flatten, omit, uniq } from 'ramda';
import { computed } from 'vue';

import { Table } from '@/modules/core';
import { useTenancy, useUser } from '@/modules/auth';
import { useCurrency } from '@/modules/core/compositions/money-currency';

import { formatDate } from '../tools/formatters';
import ItemsTable from './ItemsTable';
import { RECONCILIATION_STATUSES } from '@/modules/reconciliation';
import { createItemsData } from './ItemsTable.vue';

const TABLE_HEADER = {
  DATE: 'date',
  DOCUMENT_NUMBER: 'documentNumber',
  NET_AMOUNT: 'netAmount',
  STATUS: 'status',
  DETAILS: 'details',
};

export default {
  components: { Table, ItemsTable, Status: () => import('@/modules/reconciliation/reconciliationStatus/Status') },
  props: {
    orders: { type: Array, default: () => [] },
    supplier: { type: String, default: '' },
  },
  setup() {
    const { currentTenant } = useTenancy();
    const { formatToCurrency } = useCurrency();
    const { isAdmin } = useUser();
    return { formatToCurrency, customer: computed(() => currentTenant.value.name), isAdmin };
  },
  data() {
    return {
      rowSelection: [],
      expandableRows: {},
      selectedItemsIndexesByRowIndex: {},
      selectedData: [],
    };
  },
  computed: {
    columns() {
      return [
        {
          header: this.$t('modals.issues.unbilled.table.header.date'),
          key: TABLE_HEADER.DATE,
          minWidth: '130px',
        },
        {
          header: this.$t('modals.issues.unbilled.table.header.documentNumber'),
          key: TABLE_HEADER.DOCUMENT_NUMBER,
          minWidth: '120px',
        },
        {
          header: this.$t('modals.issues.unbilled.table.header.netAmount'),
          key: TABLE_HEADER.NET_AMOUNT,
          minWidth: '160px',
        },
        {
          header: this.$t('modals.issues.unbilled.table.header.status'),
          key: TABLE_HEADER.STATUS,
          minWidth: '120px',
        },
        {
          header: this.$t('modals.issues.unbilled.table.header.details'),
          key: TABLE_HEADER.DETAILS,
          minWidth: '220px',
        },
      ];
    },
    tableData() {
      return this.prepareUnbilledOrdersTableData(this.orders, this.customer, this.supplier);
    },
  },
  watch: {
    rowSelection(newSelected) {
      const newSelectedItemsIndexesByRowIndex = {};

      newSelected.forEach((rowIndex) => {
        const rowData = this.tableData[rowIndex];
        if (!this.selectedItemsIndexesByRowIndex[rowIndex])
          newSelectedItemsIndexesByRowIndex[rowIndex] = rowData.orderItems.map((_, index) => index);
        else newSelectedItemsIndexesByRowIndex[rowIndex] = this.selectedItemsIndexesByRowIndex[rowIndex];
      });

      this.selectedItemsIndexesByRowIndex = newSelectedItemsIndexesByRowIndex;
    },

    selectedItemsIndexesByRowIndex(newSelectedItemsIndexesByRowIndex) {
      const rowIndexesToUpdate = uniq([
        ...this.rowSelection,
        ...Object.keys(newSelectedItemsIndexesByRowIndex).map((indexKey) => Number(indexKey)),
      ]);

      this.selectedData = rowIndexesToUpdate.map((rowIndex) => {
        const rowData = clone(this.tableData[rowIndex]);
        const selectedItemsIndexes = newSelectedItemsIndexesByRowIndex[rowIndex] ?? [];
        const newOrderItems = rowData.orderItems.filter((_, index) => selectedItemsIndexes.includes(index));
        return {
          ...rowData,
          orderItems: newOrderItems,
        };
      });
    },

    selectedData(data) {
      this.$emit('selected-data', data);
    },
  },
  methods: {
    formatMoney(value) {
      return this.formatToCurrency(value) ?? '-';
    },
    formatDate,
    handleRowInnerSelection(rowIndex, selectedItemsIndexes) {
      if (selectedItemsIndexes.length) {
        this.selectedItemsIndexesByRowIndex = {
          ...this.selectedItemsIndexesByRowIndex,
          [rowIndex]: selectedItemsIndexes,
        };
      } else {
        this.selectedItemsIndexesByRowIndex = omit([rowIndex], this.selectedItemsIndexesByRowIndex);
      }

      this.$nextTick(() => {
        if (!this.rowSelection.includes(rowIndex) && selectedItemsIndexes.length)
          this.rowSelection = [...this.rowSelection, rowIndex];
      });
    },
    getDetails(order) {
      if (order.differences.length) {
        const totalDifferences = order.differences.reduce(
          (totalDifference, difference) => totalDifference + difference.amount,
          0
        );

        return {
          text: this.$i18n.tc('modals.issues.details.imbalance', 1),
          info: this.$i18n.t('modals.issues.details.totalImbalance', {
            totalImbalance: this.formatToCurrency(totalDifferences),
          }),
        };
      }
    },
    getDiffs(diff, pricing) {
      return {
        ...omit(pricing ? ['quantity'] : ['price', 'discount'], diff),
        amount: pricing ? diff.pricingAmount : diff.quantityAmount,
        type: pricing ? 'pricing' : 'quantity',
      };
    },
    createOrderItem(order, diff, customer, supplier, isPricingDiff) {
      const item = order.products.find((oi) => oi.id === diff.customerItemId);
      const orderItem = {
        orderDate: order.date,
        documentNumber: `${this.$i18n.t(`document.exports.schema.type.shortName.${order.document.type}`)} ${
          order.document.documentNumber
        }`,
        product: diff.product,
        difference: this.getDiffs(diff, isPricingDiff),
        parentContext: 'billed',
        customer,
        supplier,
      };
      if (item)
        return {
          ...item,
          ...orderItem,
        };
      else
        return {
          quantity: 0,
          discount: diff.discount?.supplierValue,
          price: diff.price?.supplierValue,
          ...orderItem,
        };
    },
    prepareUnbilledOrdersTableData(orders, customer, supplier) {
      return orders.map((order) => {
        const orderItems = flatten(
          order.differences.map((diff) => {
            const diffs = [];
            const pricingDiffExists = diff.price || diff.discount;
            if (pricingDiffExists) diffs.push(this.createOrderItem(order, diff, customer, supplier, pricingDiffExists));
            if (diff.quantity) diffs.push(this.createOrderItem(order, diff, customer, supplier));
            return diffs;
          })
        );

        const orderStatus = order.differences.length
          ? RECONCILIATION_STATUSES.NOT_APPROVED
          : RECONCILIATION_STATUSES.APPROVED;

        return {
          id: order.id,
          [TABLE_HEADER.DATE]: order.date,
          [TABLE_HEADER.DOCUMENT_NUMBER]: `${this.$i18n.t(
            `document.exports.schema.type.shortName.${order.document.type}`
          )} ${order.document.documentNumber}`,
          [TABLE_HEADER.NET_AMOUNT]: order.amount,
          [TABLE_HEADER.STATUS]: orderStatus,
          [TABLE_HEADER.DETAILS]: this.getDetails(order),
          orderItems: orderItems.map(createItemsData),
          expandable: orderItems.length,
          expandableCustomClass: 'p-0 bg-light',
          documentId: order.document.id,
        };
      });
    },
  },
};
</script>
