<template>
  <div>
    <h3 class="mb-4">{{ $t('modals.issues.missingDocuments.title') }}</h3>
    <Table
      :columns="columns"
      :data="tableData"
      :row-selection.sync="rowSelection"
      row-click-selection-toggle
      show-index
      rounded
      border
    >
      <template #cell-totalAmount="{ rowData: { totalAmount } }">
        {{ formatMoney(totalAmount) }}
      </template>
      <template #cell-date="{ rowData: { date } }">
        {{ formatDate(date) }}
      </template>
      <template #cell-details="{ rowData: { details, parentDocumentId } }">
        <a href="" @click.prevent.stop="$emit('preview-document', parentDocumentId)">{{ details }}</a>
      </template>
    </Table>
  </div>
</template>

<script>
import { clone } from 'ramda';

import { Table } from '@/modules/core';
import { useCurrency } from '@/modules/core/compositions/money-currency';

import { formatDate } from '../tools/formatters';
import { prepareMissingDocumentsTableData, TABLE_HEADER } from './compositions/missingDocuments';

export default {
  components: { Table },
  props: {
    missing: { type: Array, default: () => [] },
  },
  setup() {
    const { formatToCurrency } = useCurrency();

    return { formatToCurrency };
  },
  data() {
    return {
      rowSelection: [],
      selectedData: [],
    };
  },
  computed: {
    columns() {
      return [
        {
          header: this.$t('modals.issues.missingDocuments.table.header.date'),
          key: TABLE_HEADER.DATE,
          minWidth: '116px',
        },
        {
          header: this.$t('modals.issues.missingDocuments.table.header.documentType'),
          key: TABLE_HEADER.DOCUMENT_TYPE,
          minWidth: '115px',
        },
        {
          header: this.$t('modals.issues.missingDocuments.table.header.documentNumber'),
          key: TABLE_HEADER.DOCUMENT_NUMBER,
          minWidth: '130px',
        },
        {
          header: this.$t('modals.issues.missingDocuments.table.header.totalAmount'),
          key: TABLE_HEADER.TOTAL_AMOUNT,
          minWidth: '175px',
        },
        {
          header: this.$t('modals.issues.missingDocuments.table.header.status'),
          key: TABLE_HEADER.STATUS,
          minWidth: '117px',
        },
        {
          header: this.$t('modals.issues.missingDocuments.table.header.details'),
          key: TABLE_HEADER.DETAILS,
          minWidth: '200px',
        },
      ];
    },
    tableData() {
      return prepareMissingDocumentsTableData(this.missing);
    },
  },
  watch: {
    rowSelection(newSelected) {
      this.selectedData = newSelected.map((index) => clone(this.tableData[index]));
    },
    selectedData(data) {
      this.$emit('selected-data', data);
    },
  },
  methods: {
    formatDate,
    formatMoney(value) {
      return this.formatToCurrency(value) ?? '-';
    },
  },
};
</script>
